<template>
  <div class="category-form-modal">
    <a-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="form"
        :model="form"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
        :rules="rules"
      >
        <a-form-model-item label="分类名称" prop="name">
          <a-input v-model="form.name" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item
          v-if="type === 'add'"
          label="分类标识"
          prop="code"
          extra="10个字母以内的小写字母组成"
        >
          <a-input v-model="form.code" placeholder="请输入"></a-input>
        </a-form-model-item>
        <!-- 排序 -->
        <a-form-model-item label="排序" extra="0~9999" prop="sort">
          <a-input
            type="number"
            v-model="form.sort"
            :min="0"
            :max="9999"
            placeholder="请输入"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'AppCategoryFormModal',
  data() {
    return {
      visible: false,
      confirmLoading: false,
      type: 'add',
      form: {
        name: '',
        code: '',
        sort: undefined,
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入分类名称',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '请输入分类标识',
            trigger: 'blur',
          },
        ],
        sort: [
          {
            required: true,
            message: '请输入排序',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {
    title() {
      return this.type === 'edit' ? '编辑分类' : '新增分类';
    },
  },
  methods: {
    open({ type = 'add', detail = {} } = {}) {
      this.type = type;
      this.visible = true;
      if (type === 'edit') {
        this.form = detail;
      } else {
        this.form = {
          name: '',
          code: '',
          sort: undefined,
        };
      }
    },
    handleOk() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.type === 'add') {
            await this.addCategory();
          } else {
            await this.editCategory();
          }
          this.visible = false;
        }
      });
    },
    handleCancel() {
      this.visible = false;
    },
    // 新增请求
    async addCategory() {
      this.confirmLoading = true;
      const { err, res } = await this.$api.app.addCategory(this.form);
      if (!err) {
        this.$message.success(res.msg);
        this.$emit('ok');
      }
      this.confirmLoading = false;
    },
    // 编辑请求
    async editCategory() {
      this.confirmLoading = true;
      const { err, res } = await this.$api.app.editCategory(this.form);
      if (!err) {
        this.$message.success(res.msg);
        this.$emit('ok');
      }
      this.confirmLoading = false;
    },
  },
};
</script>

<style lang="less" scoped></style>
